<script>
	import { fade } from 'svelte/transition';
    export let country = {Capital:{GeoPt:[]}}, correct = {Capital:{GeoPt:[]}};

    export let distance = 0, percentage = 0;

    function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2-lat1);  // deg2rad below
        var dLon = deg2rad(lon2-lon1); 
        var a = 
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
            Math.sin(dLon/2) * Math.sin(dLon/2)
            ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km
        return d;
    };
    function deg2rad(deg) {
        return deg * (Math.PI/180)
    };

    $: if (country.Name) {
        distance = getDistanceFromLatLonInKm(country.Capital.GeoPt[0], country.Capital.GeoPt[1], correct.Capital.GeoPt[0], correct.Capital.GeoPt[1]);
        percentage = 100 - ((distance / 20015.086661761787) * 100);
    }
</script>

<div transition:fade class="h-9 shadow-elevate flex flex-row items-center justify-between flex-none w-full p-1 px-5 overflow-hidden rounded-full">
    <img src={country.Flag} alt="Flag of {country.Name}" class="h-full aspect-[2/1] pr-2">
    <p class="flex-1 text-xl text-left">{country.Name}</p>
    <p>{Math.round(distance)} km</p>
</div>