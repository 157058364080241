<script>
    import Wordmark from './Wordmark.svelte';
    import dayjs from 'dayjs';
    import duration from 'dayjs/plugin/duration';
    dayjs.extend(duration);

    let options = ['title', 'timeToNext'];
    let optionI = 0, pillContent = 'title';
    setInterval(() => {
        optionI ++;
        if (optionI > 1) optionI = 0;
        pillContent = options[optionI];
    }, 7500);

    export let gamedata = {'day': 0}, rainbow = false, timeToNext, focus, leaderboard = {};
</script>

<div class="sticky top-0 w-full max-w-full select-none">
    <div class="lg:hidden flex flex-row items-center justify-center flex-1 w-full px-3 pt-3 text-4xl bg-white"><Wordmark></Wordmark></div>
    <div id="masthead" class="lg:justify-center sticky top-0 flex flex-row items-center justify-around w-full gap-2 p-3 text-4xl bg-white shadow-md">
        <button on:click={() => focus = 'tutorial'}><img src="/assets/img/icons/help.svg" alt="Help"/></button>
        <button on:click={() => focus = 'stats'}><img src="/assets/img/icons/stats.svg" alt="Statistics"/></button>
        <!-- {#if leaderboard?.total}
        <button on:click={() => focus = 'leaderboard'}><img src="/assets/img/icons/trophy.svg" alt="Trophy"/></button>
        {:else} -->
        <!-- <button class="lg:block invisible hidden"><img src="/assets/img/icons/heart.svg" alt="Heart"/></button> -->
        <!-- {/if} -->
        <div class="lg:block flex-1 hidden"><Wordmark></Wordmark></div>
        <!-- <button on:click={() => window.open('https://twitter.com/Flaggle_', '_blank').focus()}><img src="/assets/img/icons/twitter.svg" alt="Twitter"/></button> -->
        <button on:click={() => focus = 'donate'}><img src="/assets/img/icons/heart.svg" alt="Heart"/></button>
        <button on:click={() => focus = 'settings'}><img src="/assets/img/icons/settings.svg" alt="Settings"/></button>
    </div>

    <div class="lg:flex-row flex flex-col items-center justify-around w-full px-2 pb-2 mb-2">
        <a href="https://izmichael.com" class="lg:w-2/3 lg:mr-2 w-11/12">
            <h3 class="max-h-8 h-8 w-full text-sm px-4 flex flex-col items-center justify-center rounded-b-full bg-[#5d9bff] text-white">Made by IzMichael</h3>
        </a>
        <!-- <button on:click={() => focus = 'donate'} class="lg:flex-1 w-full">
            <h3 class="max-h-8 h-8 lg:mb-0 mb-2 w-full text-sm px-4 flex flex-col items-center justify-center rounded-lg bg-[#ff5e5b] text-white">Support me on Ko-fi</h3>
        </button> -->
    </div>

    <div id="pill" class="relative flex flex-row items-center justify-between min-w-[20%] lg:w-2/3 w-11/12 py-0 mx-auto overflow-hidden text-lg bg-white border-2 rounded-full shadow-elevate border-grey-100">
        <div class="block h-10 py-2 pl-3 pr-2 transition-colors duration-200 ease-in-out border-r border-gray-300"><img src="/assets/img/icons/flag-outline.svg" alt="Cloud" class="w-6 h-6"/></div>
        
        <div class="flex flex-col items-center justify-center relative flex-1 h-10 overflow-hidden transition-colors duration-300 ease-in-out {rainbow == true ? 'bg-rainbow' : 'bg-white'}">
            <h1 class="{pillContent == 'title' ? 'opacity-100' : 'opacity-0'}">Flaggle #{gamedata.day}</h1>
            <h1 class="{pillContent == 'timeToNext' ? 'opacity-100' : 'opacity-0'}">{timeToNext}</h1>
        </div>

        <button on:click={() => focus = 'archive'} class="pl-2 pr-3 border-l border-gray-300"><img src="/assets/img/icons/calendar.svg" alt="Calendar"/></button>
    </div>
</div>

<style>
    #masthead button {
        @apply p-2 rounded-md bg-gray-200 cursor-pointer w-10 h-10 aspect-square transition-colors duration-200 ease-in-out;
    }
    #masthead button:hover {
        @apply bg-gray-100;
    }

    #masthead button img {
        @apply w-6 h-6;
    }

    #pill button {
        @apply py-2 cursor-pointer h-10 block transition-colors duration-200 ease-in-out;
    }
    #pill button:hover {
        @apply bg-gray-200;
    }

    #pill button img {
        @apply w-6 h-6;
    }

    #pill h1 {
        @apply absolute px-3 text-center transition-opacity duration-500 ease-in-out w-full inset-x-1;
    }

    /* Rainbow Background */
    .bg-rainbow {
        background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
        background-size: 1800% 1800%;
        animation: rainbow 8s ease infinite;
        @apply font-bold text-white;
    }

    @keyframes rainbow { 
        0% { background-position:0% 82% }
        50% { background-position:100% 19% }
        100% { background-position:0% 82% }
    }
</style>