<script>
    export let value = false, changeev = () => {};
</script>

<label class="switch">
    <input type="checkbox" bind:checked={value} on:change={changeev}>
    <span class="slider"></span>
</label>

<style>
    .switch {
        position: relative;
        display: inline-block;
        width: 2.125rem;
        height: 1.25rem;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 9999px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 0.75rem;
        width: 0.75rem;
        left: 0.25rem;
        bottom: 0.25rem;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        @apply bg-blue-500;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(0.875rem);
        -ms-transform: translateX(0.875rem);
        transform: translateX(0.875rem);
    }
</style>