<h1 class="flex flex-row items-end justify-center w-full font-semibold">
    <span>F</span>
    <span>L</span>
    <span>A</span>
    <span>G</span>
    <span>G</span>
    <span>L</span>
    <span>E</span>
    <p class="ml-2 text-xl text-green-500">S5</p>
</h1>

<style>
    span:nth-child(1), span:nth-child(7) {
        @apply text-red-500;
    }
    span:nth-child(2), span:nth-child(6) {
        @apply text-blue-500;
    }
    span:nth-child(3), span:nth-child(5) {
        @apply text-green-500;
    }
    span:nth-child(4) {
        @apply text-yellow-500;
    }
</style>